<template>
  <v-row>
    <v-col cols="6" md="4">
      <form-field-text
        v-model="obFilters.order_serial"
        label="serie"
        @input:debounce="search"
      />
    </v-col>
    <v-col cols="6" md="4">
      <form-field-text
        v-model="obFilters.order_number"
        label="number"
        @input:debounce="search"
      />
    </v-col>
    <v-col cols="6" md="4">
      <form-field-date-picker
        v-model="obFilters.created_at"
        clearable
        @change="search"
      />
    </v-col>

    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options="pagination"
        :server-items-length="serverItemsLength"
        :single-select="iMovementType === 11"
        :value="arSelected"
        disable-sort
        hide-default-footer
        selectable-key="can_referenced"
        show-expand
        show-select
        single-expand
        @item-expanded="onExpand"
        @item-selected="onChangeSelected"
        @toggle-select-all="onSelectAll"
        @update:page="paginate"
      >
        <template #top>
          <v-toolbar color="blue-grey lighten-5" flat tile>
            <v-toolbar-title class="text-subtitle-1 flex-grow-0 flex-shrink-1">
              {{ $t("selected.invoices") }}
              <v-divider
                v-show="arSelected.length"
                class="mx-4"
                inset
                vertical
              />
            </v-toolbar-title>

            <template v-if="arSelected.length">
              <v-slide-group show-arrows>
                <v-slide-item
                  v-for="obSelected in arSelected"
                  :key="`ref.${obSelected.id}`"
                >
                  <v-chip color="blue-grey mr-2" outlined x-small>
                    <span
                      class="font-weight-medium mr-2"
                      v-text="
                        `${obSelected.order_serial}${obSelected.order_number}`
                      "
                    />
                    <price-viewer
                      :currency="obSelected.currency"
                      :label="true"
                      :value="
                        useBalance
                          ? obSelected.balance_value
                          : obSelected.total_price_value
                      "
                      class="font-weight-light"
                      tag="span"
                    />
                  </v-chip>
                </v-slide-item>
              </v-slide-group>
            </template>

            <template v-if="isDebtRYA">
              <v-spacer />
              <list-amounts
                :format-value="getCurrencyFormat"
                :items="obAmounList"
                dense
                fill-height
              />
            </template>
          </v-toolbar>
        </template>

        <template #footer>
          <footer-table
            v-model="pagination.page"
            :lastPage="serverLastPage"
            :total="serverItemsLength"
          />
        </template>

        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-1 blue-grey lighten-4">
            <reference-positions-table
              :dn="useBalance"
              :items="obPositionsCache[item.id]"
              :movement-code="iMovementType"
              :value="getPositionIdList(item.id)"
              @change="onSelectPosition($event, item.id)"
            />
          </td>
        </template>

        <template #[`item.created_at`]="{ item }">
          {{ $dayjs(item.created_at).format("DD/MM/YY") }}
        </template>

        <template #[`item.total_price_value`]="{ item }">
          <price-viewer
            :currency="item.currency"
            :label="true"
            :value="item.total_price_value"
            chip
          />
        </template>

        <template #[`item.balance_value`]="{ item }">
          <price-viewer
            :currency="item.currency"
            :label="true"
            :value="item.balance_value"
            chip
          />
        </template>

        <template #[`item.balance_pending`]="{ item }">
          <price-viewer
            :currency="item.currency"
            :label="true"
            :value="item.balance_pending"
            chip
          />
        </template>

        <template #[`item.amount`]="{ item, index }">
          <form-field-text
            :disabled="
              !arSelected.length ||
              !arSelected.find((obItem) => obItem.id === item.id)
            "
            :value="item.amount"
            hide-label
            input-type="number"
            label="amount"
            @input:debounce="onUpdateInvoiceAmount($event, index)"
          />
        </template>

        <template #[`item.order_number`]="{ item }">
          <span class="font-weight-bold" v-text="item.order_serial" />
          <span class="ml-2" v-text="item.order_number" />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Inject, Mixins } from "vue-property-decorator";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import type {
  DataTableHeader,
  DataTableSelect,
  DataTableSelectAll,
} from "@/mixins/DataTableMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import type { InvoicePositionData } from "@planetadeleste/vue-mc-gw";
import {
  InvoiceCollection,
  InvoiceMovementType,
  InvoicePositionCollection,
  InvoiceType,
  InvoiceTypeCollection,
} from "@planetadeleste/vue-mc-gw";
import type { Response } from "vue-mc";

import FormFieldDatePicker from "@/components/form/fields/DatePicker.vue";
import PriceViewer from "@/components/common/PriceViewer.vue";
import ReferencePositionsTable from "@/modules/invoices/components/references/ReferencePositionsTable.vue";
import CacheMixin from "@/mixins/CacheMixin";
import { find, findIndex, forEach, get, isNumber, set, unset } from "lodash";
import PaginateMixin from "@/mixins/PaginateMixin";
import FooterTable from "@/components/common/data-table/Footer.vue";
import { number } from "mathjs";
import AnimatedNumber from "animated-number-vue";
import type { InvoiceData, Ref } from "@/types/utils";
import useMath from "@/composables/math";
import type { ListAmountItem } from "@/components/common/ListAmounts.vue";
import ListAmounts from "@/components/common/ListAmounts.vue";

interface ReferencesSearchFilter {
  customer: number;
  currency: number;
  signed: 1 | 0;
  cash: 1 | 0;
  credit: 1 | 0;
  no_check_skip_balance: 1 | 0;
  unpaid: 1 | 0;
  invoiceMovementType: number[];
  invoice_type_id: number[];
  order_serial: string;
  order_number: string;
  created_at: string;
}

const { sumBy, math } = useMath();

@Component({
  components: {
    ListAmounts,
    AnimatedNumber,
    FooterTable,
    FormFieldDatePicker,
    PriceViewer,
    ReferencePositionsTable,
  },
})
export default class ReferencesInvoicesSearch extends Mixins(
  InvoiceMixin,
  DataTableMixin,
  CacheMixin,
  PaginateMixin
) {
  @Inject() readonly obSelected!: Ref<InvoiceData[]>;

  obFilters: Partial<ReferencesSearchFilter> = {};
  obCollection: InvoiceCollection = new InvoiceCollection();
  obInvoiceTypeCollection: InvoiceTypeCollection = new InvoiceTypeCollection();
  obPositionsCache: Record<number, InvoicePositionData[] | string> = {};
  loading = false;

  get arSelected() {
    return this.obSelected.value;
  }

  get items(): InvoiceData[] | Record<string, any> {
    return this.obCollection.length ? this.obCollection.getModelList() : [];
  }

  get obAmounList(): Record<string, ListAmountItem> | undefined {
    if (!this.isDebtRYA) {
      return undefined;
    }

    return {
      total: {
        animated: true,
        value: this.fPriceLimit,
        label: "amount.total",
      },
      amount: {
        value: this.fSelectedAmount,
        label: "amount",
      },
      balance: {
        value: this.fBalanceAmount,
        label: "invoice.balance.amount",
        pined: !!this.fPriceLimit,
      },
    };
  }

  /**
   * Invoice movement type is DEBIT NOTE
   * @returns {boolean}
   */
  get bIsDN(): boolean {
    return this.iMovementType === InvoiceMovementType.CODE_DEBIT_NOTE;
  }

  get fSelectedAmount(): number {
    return this.fPriceLimit && this.arSelected.length
      ? sumBy(this.arSelected, (obItem) => obItem.amount ?? 0)
      : 0;
  }

  get fBalanceAmount(): number {
    return this.fPriceLimit && this.fSelectedAmount
      ? math.subtract(this.fPriceLimit, this.fSelectedAmount)
      : 0;
  }

  get fPriceLimit(): number {
    return this.isDebtRYA ? number(this.obInvoice.get("price_limit", 0)) : 0;
  }

  get useBalance(): boolean {
    return ![
      InvoiceMovementType.CODE_DEBIT_NOTE,
      InvoiceMovementType.CODE_RECEIPT_CORRECT,
      InvoiceMovementType.CODE_CANCEL_DEBT,
    ].includes(this.iMovementType);
  }

  created() {
    this.sMethod = "search";
  }

  mounted() {
    this.noParamPage.value = true;
    const arHeaders: DataTableHeader[] = [
      { text: "date", value: "created_at" },
      {
        text: "invoice.dgi.number",
        value: "order_number",
        sortable: false,
      },
      { text: "invoice.type", value: "cfe_name", sortable: false },
      {
        text: "total.invoice",
        value: this.useBalance ? "balance_value" : "total_price_value",
      },
      {
        text: "total.balance",
        value: "balance_pending",
      },
      { text: "", value: "data-table-expand" },
    ];

    if (this.isDebtRYA) {
      const idx = findIndex(arHeaders, { value: "data-table-expand" });
      arHeaders.splice(idx - 1, 0, { text: "amount", value: "amount" });
    }

    this.setDTHeaders(arHeaders);
    this.search();
  }

  beforeDestroy() {
    this.noParamPage.value = false;
  }

  async search() {
    if (!this.obInvoice.customer_id) {
      return;
    }

    this.loading = true;
    await this.preloadInvoiceType();
    await this.setFilters();

    const obInvoiceCollection = new InvoiceCollection();
    obInvoiceCollection
      .filterBy(this.obFilters)
      .page(this.currentPage as number);
    const obResponse = (await obInvoiceCollection.fetch()) as Response<
      InvoiceData[]
    >;

    this.obCollection.clear();
    if (obResponse) {
      const obData = obResponse.getData();
      this.mapPagination(obData);
      this.obCollection.add(obData.data);
    }

    this.obCollection.each((obModel) => {
      this.$set(this.obPositionsCache, obModel.id, "loading");

      if (this.bIsDN) {
        obModel.set("can_referenced", true);
      }
    });
    this.loading = false;
  }

  onExpand(obData: DataTableSelect<InvoicePositionData>) {
    const obItem = obData?.item;
    const open = obData.value;

    if (!open || !obItem || this.obPositionsCache[obItem.id] != "loading") {
      return;
    }

    this.$set(this.obPositionsCache, obItem.id, "loading");
    const obFilters: Record<string, any> = { invoice: obItem.id };

    if (this.iMovementType === InvoiceMovementType.CODE_CANCEL_DEBT) {
      obFilters.with_invoice_item = 1;
    }

    const obPositionCollection = new InvoicePositionCollection();
    obPositionCollection
      .filterBy(obFilters)
      .fetch()
      .then((response: Response<InvoicePositionData[]> | null) => {
        if (!response || !response.getData()) {
          return;
        }

        this.$set(this.obPositionsCache, obItem.id, response.getData().data);
      });
  }

  /**
   * Add item to selection
   * @param obItem
   */
  select(obItem: InvoiceData) {
    if (!obItem.id || find(this.arSelected, { id: obItem.id })) {
      return;
    }

    this.obSelected.value.push(obItem);
  }

  /**
   * Remove item from selection
   * @param obItem
   */
  unselect(obItem: InvoiceData) {
    const iSelectedIndex = findIndex(this.arSelected, { id: obItem.id });

    if (!obItem.id || iSelectedIndex < 0) {
      return;
    }

    this.obSelected.value.splice(iSelectedIndex, 1);
  }

  /**
   * Add/Update item from selection
   * @param arPositionListId
   * @param iInvoiceId
   */
  onSelectPosition(arPositionListId: number[], iInvoiceId: number) {
    const obSearchData: Partial<InvoiceData> = { id: iInvoiceId };
    let obInvoiceData = find(this.arSelected, obSearchData);

    if (!obInvoiceData) {
      obInvoiceData = find(this.items, obSearchData);

      if (!obInvoiceData) {
        return;
      }
    }

    if (!isNumber(obInvoiceData)) {
      set(obInvoiceData, "position_id_list", arPositionListId);
      const iSelectedIndex = findIndex(this.arSelected, obSearchData);

      if (iSelectedIndex === -1) {
        this.select(obInvoiceData);
      } else {
        this.obSelected.value.splice(iSelectedIndex, 1, obInvoiceData);
      }
    }
  }

  /**
   * Toggle item selection
   * @param obData
   */
  onChangeSelected(obData: DataTableSelect<InvoiceData>) {
    if (!obData.item) {
      return;
    }

    const obInvoiceData = obData.item;

    if (obData.value) {
      const sValue = obData.value ? obInvoiceData.balance_value : "";
      set(obInvoiceData, "amount", sValue);

      // Clone item and push into selected
      const obClonedItem = { ...obInvoiceData };
      this.select(obClonedItem);
    } else {
      set(obInvoiceData, "amount", "");
      this.unselect(obInvoiceData);
    }
  }

  /**
   * Select/Unselect all items in current view
   * @param obData
   */
  onSelectAll(obData: DataTableSelectAll<InvoiceData>) {
    forEach(obData.items, (obItem) => {
      this.onChangeSelected({ item: obItem, value: obData.value });
    });
  }

  onUpdateInvoiceAmount(fValue: number, index: number) {
    const arItems = [...this.arSelected];
    const obInvoiceItem = this.items.slice(index, index + 1)[0];

    if (!obInvoiceItem) {
      return;
    }

    index = findIndex(arItems, { id: obInvoiceItem.id });
    const obItem = find(arItems, { id: obInvoiceItem.id }) as
      | InvoiceData
      | undefined;

    if (!obItem) {
      return;
    }

    const fBalance = obInvoiceItem.balance_value as number;

    if (fValue > fBalance) {
      fValue = fBalance;
    }

    obItem.amount = fValue;
    obItem.balance_value = fValue;
    this.obSelected.value.splice(index, 1, obItem);
  }

  getPositionIdList(iInvoiceId: number) {
    const obInvoiceData = find(this.arSelected, { id: iInvoiceId });
    const arListId = obInvoiceData
      ? get(obInvoiceData, "position_id_list")
      : [];

    return arListId;
  }

  /**
   * Return invoice movement code from ID
   * @param {number} invoiceMovementTypeId
   */
  async getMovementTypeCode(invoiceMovementTypeId: number) {
    const obInvoiceMovementTypeData = this.cache(
      invoiceMovementTypeId,
      "InvoiceMovementType"
    );
    const obInvoiceMovementType = new InvoiceMovementType();

    if (obInvoiceMovementTypeData) {
      obInvoiceMovementType.set(obInvoiceMovementTypeData);
    } else {
      obInvoiceMovementType.set("id", invoiceMovementTypeId);
      await obInvoiceMovementType.fetch();
    }

    return obInvoiceMovementType.code
      ? number(obInvoiceMovementType.code)
      : null;
  }

  /**
   * Get invoice type code as number from remote invoice type
   * @param {Number} iInvoiceTypeId
   */
  async getInvoiceTypeCode(iInvoiceTypeId: number): Promise<number> {
    const obInvoiceType = new InvoiceType({ id: iInvoiceTypeId });
    await obInvoiceType.fetch();

    return number(obInvoiceType.get("code", 0));
  }

  private async preloadInvoiceType() {
    // Preload invoice types to used un filters
    if (!this.obInvoiceTypeCollection.length) {
      const obTypeFilter = { code: ["101", "102", "111", "112", "182", "901"] };
      await this.obInvoiceTypeCollection.filterBy(obTypeFilter).fetch();
    }
  }

  /**
   * Set search filters
   * @private
   */
  private async setFilters() {
    // Force to load only invoices with same currency
    if (this.currency) {
      this.obFilters.currency = this.currency.id;
    }

    this.obFilters.customer = this.obInvoice.customer_id;
    this.obFilters.signed = 1;
    let sTypeCode = this.sTypeCode;

    if (this.obInvoice.is_cash) {
      this.obFilters.cash = 1;
    } else {
      this.obFilters.credit = 1;
    }

    if (this.obInvoice.invoice_type_id && !this.sTypeCode) {
      sTypeCode = await this.getInvoiceTypeCode(this.obInvoice.invoice_type_id);
    }

    // Check by invoice movement type (2: Devolucion, 5: Nota debito)
    if (this.iMovementType) {
      const sMovementCode = this.iMovementType; // await this.getMovementTypeCode(this.iMovementType);
      const arInvoiceType: number[] = [];
      const arInvoiceTypeCode: (string | number)[] = [];

      // Devolucion, Cobranza or Cobranza RYA returns TipoCFE = 101|111
      if (
        [
          InvoiceMovementType.CODE_REFOUND,
          InvoiceMovementType.CODE_DEBT,
          InvoiceMovementType.CODE_DEBT_RYA,
        ].includes(sMovementCode)
      ) {
        arInvoiceTypeCode.push(InvoiceType.CODE_ETICKET);
        arInvoiceTypeCode.push(InvoiceType.CODE_EFACTURA);

        // Filter by movement type as sales
        set(this.obFilters, "invoiceMovementType", [
          InvoiceMovementType.CODE_SALES,
        ]);

        // Load only unpaid invoices for credit type
        // if (!this.obInvoice.is_cash) {
        //   set(this.obFilters, "unpaid", 1);
        // }

        set(this.obFilters, "unpaid", 1);

        // Skip checking "skip_balance" (sales invoices has skip_balance = true to hide balance)
        set(this.obFilters, "no_check_skip_balance", 1);

        // Type of COBRANZA use only credit invoices
        if (
          [
            InvoiceMovementType.CODE_DEBT,
            InvoiceMovementType.CODE_DEBT_RYA,
          ].includes(sMovementCode)
        ) {
          // Invoice of type e-Recibo (701) do not filter by credit/cash
          // if (sTypeCode === 701) {
          //   unset(this.obFilters, "credit");
          // } else {
          //   set(this.obFilters, "credit", 1);
          // }

          set(this.obFilters, "credit", 1);
          unset(this.obFilters, "cash");
        }
      }
      // Nota de Débito returns TipoCFE = 102|112
      else if (InvoiceMovementType.CODE_DEBIT_NOTE === sMovementCode) {
        arInvoiceTypeCode.push(InvoiceType.CODE_NCETICKET);
        arInvoiceTypeCode.push(InvoiceType.CODE_NCEFACTURA);

        // Filter by movement type as sales
        set(this.obFilters, "invoiceMovementType", [
          InvoiceMovementType.CODE_REFOUND,
        ]);
      }
      // Cancel/Modify receipt (resguardo) returns TipoCFE = 182
      else if (sMovementCode === InvoiceMovementType.CODE_RECEIPT_CORRECT) {
        arInvoiceTypeCode.push(InvoiceType.CODE_ERESGUARDO);
        this.obFilters.customer = undefined;
      }
      // Provider pay InvoiceMovementType == 9
      else if (sMovementCode === InvoiceMovementType.CODE_PAY) {
        set(this.obFilters, "credit", 1);
        unset(this.obFilters, "cash");
        unset(this.obFilters, "signed");
      }
      // Cancel debt (cobranza) returns type e-Cobranza (901)
      else if (sMovementCode === 14) {
        arInvoiceTypeCode.push(InvoiceType.CODE_ECOBRANZA);
        set(this.obFilters, "not_referenced", 1);
        set(this.obFilters, "invoiceTypeCode", InvoiceType.CODE_ECOBRANZA);
        set(
          this.obFilters,
          "invoiceMovementTypeCode",
          InvoiceMovementType.CODE_DEBT
        );
      }

      if (arInvoiceTypeCode.length) {
        this.obInvoiceTypeCollection.each((obModel) => {
          if (arInvoiceTypeCode.includes(obModel.code)) {
            arInvoiceType.push(obModel.id);
          }
        });
      }

      this.obFilters.invoice_type_id = arInvoiceType;
    }
  }
}
</script>
